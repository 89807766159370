<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
// filters
const filter_customers = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const customers = ref()
const provisions = ref([])

const provisions_sidebar = ref(false)

onMounted(() => {
    get_customers()
})

const get_customers = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/providebit/get-all-bexio-customers")
        .then(response => {
            console.log(response.data)
            customers.value = response.data
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const show_provisions = (data) => {
    provisions.value = data.provision
    provisions_sidebar.value = true
}


const get_provision_totals = (provision) => {
    let total = 0
    /*
    provision.forEach((item) => {
        item.forEach((product) => {
            total += product.amount
        })
    })
    */
    return total
}

const bexio_order = (id) => {
    window.open('https://office.bexio.com/index.php/kb_order/show/id/' + String(id) + '#invoices','_blank')
}
const bexio_invoice = (id) => {
    window.open('https://office.bexio.com/index.php/kb_invoice/show/id/' + String(id), '_blank')
}

const bexio_customer = (id) => {
    window.open('https://office.bexio.com/index.php/contact/show/id/' + String(id), '_blank')
}

const open_url = (url) => {
    window.open(url, '_blank')
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2" style="width: 300px">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_customers['global'].value" placeholder="Suche" />
                    </span>
                </template>
                <template #end>
                    <Button label="Excel Export"  @click="open_url('https://api.snpy.ch/providebit/get-bexio-metrics')" class="w-auto mr-1" type="button" icon="pi pi-file-excel" v-tooltip.left="'Bexio Metrics exportieren'" />
                </template>
            </Toolbar>
        </div>
        <DataTable v-model:filters="filter_customers"  :value="customers" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true">
            <Column expander style="width: 5rem" />
            <Column field="id" header="Kunden">
                <template #body="slotProps">
                    <Chip :label="slotProps.data.customer_nr" class="bg-primary text-white mr-2" /><span class="font-bold">{{ slotProps.data.firstname }} {{slotProps.data.lastname}}</span>
                </template>
            </Column>
            <Column field="food_orders" header="Lebensmittel">
                <template #body="slotProps">
                    <span class="text-green-600 font-bold" v-tooltip.top="'Aktive Lebensmittel Aufträge'">{{ String(slotProps.data.food_orders) }}</span> / <span v-tooltip.top="'Abgelaufene Lebensmittel Aufträge'" class="text-red-600 font-bold">0</span>
                </template>
            </Column>
            <Column field="wheat_orders" header="Weizen">
                <template #body="slotProps">
                    <span class="text-green-600 font-bold" v-tooltip.top="'Aktive Weizen Aufträge'">{{ String(slotProps.data.wheat_orders) }}</span> / <span v-tooltip.top="'Stornierte Weizen Abos'" class="text-red-600 font-bold">0</span>
                </template>
            </Column>
            <Column header="Auslieferungen">
                <template #body>
                    <span v-tooltip.top="'Noch nicht erledigt'" class="text-red-600 font-bold">0</span> / <span class="text-green-600 font-bold" v-tooltip.top="'Erledigt'">0</span>
                </template>
            </Column>
            <Column header="E-Mails" sortable>
                <template #body>
                    <span v-tooltip.top="'Letzten 14 Tage'" class="text-red-600 font-bold">0</span> / <span class="text-green-600 font-bold" v-tooltip.top="'Total E-Mails'">0</span>
                </template>
            </Column>
            <Column header="Versorgung" sortable>
                <template #body="slotProps">
                    <Button label="Versorgung" @click="show_provisions(slotProps.data)" class="mr-2 p-button w-auto" icon="pi pi-box" badge="0" />
                </template>
            </Column>
            <Column field="lastname" hidden></Column>
            <Column field="firstname" hidden></Column>
            <Column field="customer_nr" hidden></Column>
            <Column field="id" header="">
                <template #body="slotProps">
                    <Button label="Bexio" @click="bexio_customer(slotProps.data.id)" class="mr-2 p-button w-auto" style="background-color: #0d2f3b; color: #bbdc00; border: 1px solid #bbdc00" icon="pi pi-eye" />
                </template>
            </Column>
        </DataTable>
    </BlockUI>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Sidebar v-model:visible="provisions_sidebar" position="right" style="width: 70%">
        <div class="flex p-4">
            <TabView class="col-12" ref="tabview4">
                <TabPanel>
                    <template #header>
                        <i class="pi pi-box mr-2"></i>
                        <span class="mr-3">Versorgung</span>
                        <Badge value="0" />
                    </template>
                    <div class="mt-2 flex justify-content-end align-items-center text-primary">
                        <i class="pi pi-file-excel mr-2"></i>
                        <span class="font-bold mr-2 hover:text-blue-200 cursor-pointer">Excel Export</span>
                    </div>
                    <div rounded class="grid mt-3 overflow-hidden shadow-3 border-200 p-2">
                        <div class="col-1 font-bold text-right border-blue-200 border-solid border-bottom-1">Artikelnummer</div>
                        <div class="col-4 font-bold border-blue-200 border-solid border-bottom-1">Beschreibung</div>
                        <div class="col-1 font-bold text-center border-blue-200 border-solid border-bottom-1">Bestellt</div>
                        <div class="col-1 font-bold text-center border-blue-200 border-solid border-bottom-1">Ausgeliefert</div>
                        <div class="col-1 font-bold text-center border-blue-200 border-solid border-bottom-1">Erneuert</div>
                        <div class="col-1 font-bold text-center border-blue-200 border-solid border-bottom-1">auf Lager</div>
                        <div class="col-1 font-bold text-right border-blue-200 border-solid border-bottom-1">Einheitswert</div>
                        <div class="col-2 font-bold text-right border-blue-200 border-solid border-bottom-1">Gesamtwert</div>
                        <template v-for="provision in provisions" :key="provision">
                            <template v-for="product, key2 in provision" :key="product">
                                <div class="col-1 text-right border-blue-100 border-solid border-bottom-1">{{ key2 }}</div>
                                <div class="col-4 font-bold border-blue-100 border-solid border-bottom-1"> {{ product["text"] }}</div>
                                <div class="col-1 text-center border-blue-100 border-solid border-bottom-1"> {{ product["amount"] }}</div>
                                <div class="col-1 text-center border-blue-100 border-solid border-bottom-1"> {{ product["amount"] }}</div>
                                <div class="col-1 text-center border-blue-100 border-solid border-bottom-1"> {{ product["amount"] }}</div>
                                <div class="col-1 text-center border-blue-100 border-solid border-bottom-1 font-bold"> {{ product["amount"] }}</div>
                                <div class="col-1 text-right border-blue-100 border-solid border-bottom-1"> {{ format_currency_without_prefix(product["unit_price"]) }}</div>
                                <div class="col-2 text-right border-blue-100 border-solid border-bottom-1"> {{ format_currency(product["position_total"]) }}</div>
                            </template>
                        </template>
                        <div class="col-8 font-bold text-right">Total</div>
                        <div class="col-1 font-bold text-center">0</div>
                        <div class="col-1 font-bold text-right">0</div>
                        <div class="col-2 font-bold text-right">CHF 0.00</div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-file mr-2"></i>
                        <span class="mr-3">Aufträge</span>
                        <Badge value="0" />
                    </template>
                    empty
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-truck mr-2"></i>
                        <span class="mr-3">Auslieferungen</span>
                        <Badge value="0" />
                    </template>
                    empty
                </TabPanel>
                <TabPanel>
                    <template #header>
                        <i class="pi pi-envelope mr-2"></i>
                        <span class="mr-3">E-Mails</span>
                        <Badge value="0" />
                    </template>
                    empty
                </TabPanel>
            </TabView>
        </div>
    </Sidebar>
</template>